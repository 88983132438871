import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Card = styled.div`
  margin: 30px 0;
`
const CardImage = styled.div`
  box-shadow: 3px 3px 5px #aeaeae;
  margin: auto;
  margin-bottom: 15px;
  border-radius: 3px;
  height: 450px;
  width: 600px;
  transition: 180ms ease-in-out;

  @media (max-width: 1000px) {
    height: 300px;
    width: 400px;
  }

  @media (max-width: 424px) {
    height: 230px;
    width: 285px;
  }
  &:hover {
    box-shadow: 1px 1px 3px #aeaeae;
  }

  &img {
    margin: 0;
  }
`

class Website extends Component {
  static propTypes = {
    link: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string,
  }

  render() {
    let { image, link, title, desc } = this.props
    return (
      <Card>
        <CardImage>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div
              style={{
                background: `url(${image}) no-repeat top center`,
                height: `100%`,
                maxWidth: `100%`,
                backgroundSize: `cover`,
              }}
            />
          </a>
        </CardImage>
        <h4 className="title" style={{ marginBottom: 10 }}>
          {title}
        </h4>
        <div className="desc" style={{ fontSize: `70%` }}>
          {desc}
        </div>
      </Card>
    )
  }
}

export default Website
