import React, { Component } from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"

import Website from "../components/Website"
import Link from "../components/Link"

import styled from "styled-components"
import {
  FaLinkedin,
  FaGithub,
  FaCodepen,
  FaFileDownload,
  FaHome,
} from "react-icons/fa"

import fly from "../images/fly.png"
import motoramaPic from "../images/motorama.png"
import vanaramaPic from "../images/vanarama.png"
import liveforwindPic from "../images/live4wind.png"
import growingzPic from "../images/growingz.png"
import utPic from "../images/ultimostestigos.png"
import pierresangPic from "../images/pierresang.png"
import magnyficentPic from "../images/magnyficent.png"

import { withPrefix } from "gatsby"

const Home = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  & a {
    background-color: #fff;
    box-shadow: 0 0 0 white;
  }
`

const Tiny = styled.div`
  font-size: 70%;
  margin-top: 10px;
  p {
    margin: 0;
  }
`

class Cv extends Component {
  render() {
    return (
      <Layout>
        <div className="cv-container text-center">
          <Helmet
            title={`Antoine's CV`}
            meta={[
              { itemprop: "name", content: `Antoine's CV` },
              {
                itemprop: "description",
                content: "Some of the things I've done",
              },
              { itemprop: "image", content: fly },
              { name: "keywords", content: "CV, Resume" },
              { property: "og:title", content: `Antoine's CV` },
              { property: "og:image:url", content: fly },
              { property: "og:image:width", content: "750" },
              { property: "og:image:height", content: "750" },
              { property: "og:url", content: "https://www.magnyficent.com/cv" },
              {
                property: "og:description",
                content: "Some of the things I've done",
              },
              { property: "twitter:title", content: `Antoine's CV` },
              {
                property: "twitter:description",
                content: "Some of the things I've done",
              },
              { property: "twitter:image:src", content: "👋" },
            ]}
          />

          <Home>
            <Link to="/">
              <FaHome />
            </Link>
          </Home>
          <h3>🙋‍♂️</h3>
          <h4>{`Hi! I'm Antoine`}</h4>
          <p />
          <p>
            a developer working on <strong>awesome</strong> things
          </p>
          <div>I sometimes teach. Kitesurfing and coding mostly</div>
          <p>possibly philosophy as well after a few 🍻!</p>

          <p>
            Here's my latest CV:
            <a
              href={withPrefix("/AntoineMagnyCV.pdf")}
              download="AntoineMagnyCV.pdf"
              style={{ paddingLeft: "5px" }}
            >
              <FaFileDownload />
            </a>
          </p>

          <h3>My latest projects</h3>
          <div className="col-xs-12 col-md-2 col-md-offset-3">
            <Website
              title="Motorama"
              image={motoramaPic}
              link="https://www.motorama.com"
              desc="Website platform (scaleable, connected to national car and van DB). Ruby on Rails <=> GraphQL <=> Apollo <=> React + microservices"
            />

            <Website
              title="Vanarama"
              image={vanaramaPic}
              link="https://www.vanarama.com"
              desc="Credit Check tool + Customer DB. Apis <=> Ruby on Rails <=> GraphQl <=> Apollo <=> React"
            />

            <Website
              title="Live for Wind"
              image={liveforwindPic}
              link="https://liveforwind.com"
              desc="The search engine for your windy adventures. Ruby on Rails with a Skyscanner API"
            />

            <Website
              title="Magnyficent"
              image={magnyficentPic}
              link="https://magnyficent.com"
              desc="(Inception!) A blog. Gatsby"
            />

            <Website
              title="Pierre Sang"
              image={pierresangPic}
              link="https://pierresang.com"
              desc="Frontend + Backend (Ruby on Rrails) + SMS bot (Twilio API) + Booking management platform and payment platform integrations."
            />

            <Website
              title="Ultimos Testigos"
              image={utPic}
              link="http://ultimostestigos.com"
              desc="Static website for a book and exhibition in Mexico. Middleman"
            />

            <Website
              title="Growingz"
              image={growingzPic}
              link="https://growingz.com"
              desc="Nature's marketplace. Ruby on Rails. Coming Soon"
            />
          </div>

          <p>Before that, I worked in a FinTech startup as a Fullstack dev</p>
          <p>
            And before that I worked as a:
            <span className="block">
              Restaurant Manager, Kitchen Manager, Au Pair, Butcher, Caterer,
              Concierge, Sommelier, Marketer, Factory manager, Blogger, Kitesurf
              instructor...
            </span>
          </p>
          <div className="justify">
            <strong>
              {`"A human being should be able to change a diaper, plan an invasion,
            butcher a hog, conn a ship, design a building, write a sonnet,
            balance accounts, build a wall, set a bone, comfort the dying, take
            orders, give orders, cooperate, act alone, solve equations, analyze
            a new problem, pitch manure, program a computer, cook a tasty meal,
            fight efficiently, die gallantly. Specialization is for insects." 🐜`}
            </strong>
          </div>
          <div className="text-right">Robert Heinlein</div>

          <Tiny>
            <a
              href="https://www.linkedin.com/in/antoinemagny"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "10px", margin: "5px", fontSize: "130%" }}
            >
              <FaLinkedin />
            </a>
            <a
              href="https://codepen.io/Antoine007"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "10px", margin: "5px", fontSize: "130%" }}
            >
              <FaCodepen />
            </a>
            <a
              href="https://github.com/Antoine007"
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "10px", margin: "5px", fontSize: "130%" }}
            >
              <FaGithub />
            </a>
            <p>
              📞 <a href="tel:+33685188661">+33685188661</a>
            </p>
            <p>💌 mrmagny [at] gmail ⚫ com</p>
          </Tiny>
        </div>
      </Layout>
    )
  }
}

export default Cv
